import { featuresData } from "./DevelopersPageData";
import { Box, Typography, styled } from "@mui/material";
import standOutBtn from "../../../images/landing/standOutBtn.png";

const BecomeFutureReadyCard = ({
  item,
  index,
}: {
  item: FutureReadyProps;
  index: number;
}) => {
  return (
    <div>
      <FutureReadyWrapper key={index} item={item}>
        <div className="flex1">
          {item.subImage && <ComingSoonBtn>{item.subImageMsg}</ComingSoonBtn>}
          <h1>{item.header}</h1>
          <>{item.subheader}</>
        </div>
        <div className="flex2">
          <div className="illustration">
            <img
              src={item.img}
              alt={item.alt}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </FutureReadyWrapper>
    </div>
  );
};

const Features = () => {
  return (
    <Wrapper>
      <div>
        <Header>
          <img src={standOutBtn} alt="earn more" />
          <h2>Stand Out to Earn More</h2>
          <FutureReadySubtext>
            Showcase your expertise with Streetcred. Our AI-powered tools helps you learn & build portfolio to attract opportunities.
          </FutureReadySubtext>
        </Header>
      </div>

      <FutureReadyContainer>
        {featuresData.map((m, i) => (
          <BecomeFutureReadyCard item={m} index={i} key={i} />
        ))}
      </FutureReadyContainer>
    </Wrapper>
  );
};

export default Features;

interface FutureReadyProps {
  img: string;
  header: string;
  subheader: React.ReactNode;
  alt?: string;
  id: number;
  subImage?: boolean;
  subImageMsg?: string;
}

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  paddingTop: 80,
  [theme.breakpoints.down(800)]: {
    paddingTop: 20,
  },
  "&>h2": {
    margin: "auto",
    width: 831,
    fontSize: "3rem",
    textAlign: "center",
    lineHeight: "4.5rem",
    color: "#fefefe",
    fontWeight: 700,
    padding: "0px 0 20px 0",
    [theme.breakpoints.down(1025)]: {
      width: "663px",
      fontSize: "2.5rem",
      lineHeight: "3.19rem",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: "1.875rem",
      lineHeight: "2.8125rem",
      width: "100%",
    },
  },
  "&>p": {
    width: 739,
    margin: "auto",
    textAlign: "center",
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: "2rem",
    color: "#fefefe",
    paddingBottom: 115,

    [theme.breakpoints.down(1025)]: {
      width: "663px",
      paddingBottom: 60,
    },
    [theme.breakpoints.down(800)]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      width: "100%",
    },
    [theme.breakpoints.down(500)]: {
      paddingBottom: 40,
    },
  },
}));

const FutureReadyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 100,
  overflowY: "auto",
  [theme.breakpoints.down(1025)]: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: 100,
    margin: "0 0",
  },

  [theme.breakpoints.down(600)]: {
    margin: "0",
    gap: 28,
  },
}));

const FutureReadySubtext = styled(Typography)(({ theme }) => ({
  width: 511,
  marginBottom: 100,
  [theme.breakpoints.down(900)]: {
    fontSize: "1.875rem",
    lineHeight: "2.8125rem",
    width: 240,
  },
}));

const Wrapper = styled("section")(({ theme }) => ({
  paddingBottom: "160px",
  maxWidth: 1312,
  width: "calc(100% - 128px)",
  margin: "auto",
  [theme.breakpoints.down(1025)]: {
    width: "calc(100% - 81px)",
  },
  [theme.breakpoints.down(900)]: {
    width: "100%",
  },
  [theme.breakpoints.down(800)]: {
    paddingTop: "40px",
    paddingBottom: "80px",
  },
}));

const FutureReadyWrapper = styled(Box)<{ item: FutureReadyProps }>(
  ({ theme, item }) => ({
    display: "flex",
    justifyContent: "space-between",
    color: "#fefefe",
    alignItems: "center",
    borderRadius: 20,
    gap: 40,
    overflow: "hidden",
    flexDirection: item.id === 2 ? "row-reverse" : "row",
    // height: "100vh",
    // scrollSnapAlign: "start",

    [theme.breakpoints.down(900)]: {
      flexDirection: "column",
      gap: 28,
      height: "fit-content",
      width: "744px",
      margin: "auto",
    },

    [theme.breakpoints.down(800)]: {
      height: "fit-content",
      width: "100%",
      margin: "auto",
    },
    "& .flex1": {
      width: "539px",

      [theme.breakpoints.down(900)]: {
        width: "100%",
      },

      "&>h1": {
        fontSize: "3rem",
        fontWeight: 700,
        lineHeight: "3.828125rem",
        marginBottom: 20,

        [theme.breakpoints.down(1024)]: {
          fontSize: "2.5rem",
          lineHeight: "3.9rem",
        },

        [theme.breakpoints.down(720)]: {
          fontSize: "1.5rem",
          lineHeight: "1.91375rem",
        },
      },

      "&>p": {
        fontSize: "1.5rem",
        fontWeight: 400,
        lineHeight: "2.125rem",
        // width: "360px",
        [theme.breakpoints.down(1024)]: {
          fontSize: "1.25rem",
          lineHeight: "2rem",
        },
        [theme.breakpoints.down(900)]: {
          width: "675px",
        },
        [theme.breakpoints.down(800)]: {
          width: "100%",
        },
        [theme.breakpoints.down(720)]: {
          fontSize: "1rem",
          lineHeight: "1.5rem",
        },
      },
    },

    "& .flex2": {
      // width: "50%",
      [theme.breakpoints.down(900)]: {
        width: "100%",

        justifyContent: "flex-end",
      },

      "&> .illustration": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        zIndex: 1,
        [theme.breakpoints.down(900)]: {
          height: "100%",
          width: "100%",
        },
      },
    },
  })
);

const ComingSoonBtn = styled("div")(({ theme }) => ({
  color: "#fefefe",
  background: "linear-gradient(to bottom, #222325 0%, #383A3D 100%)",
  padding: "4px 20px",
  display: "flex",
  gap: 8,
  alignItems: "center",
  border: "1px solid #222325",
  borderRadius: 20,
  width: "fit-content",
  marginBottom: 20,
}));
