// import PageSwitch from "../switch/PageSwitch";
import { RefCode } from "../../../libs/refCode";
import { logEvent } from "../../../libs/amplitude";
import GithubClient from "../../../libs/GithubClient";
import { Typography, styled } from "@mui/material";
import { githubSvg } from "../../UserHome/Base/SVG";

const HeroMessage = ({ messageId }: { messageId: number }) => {
  switch (messageId) {
    case 0:
      return (
        <>
          <h1>
            Online Presence: The Unseen {""}
            <EmphasizedText>Multiplier</EmphasizedText>
          </h1>
          <PaperHeadText>
            With Tublian, let's turn your code into credibility.
          </PaperHeadText>
        </>
      );
    case 1:
      return (
        <>
          <MainText>
            Your Code Speaks. But Does the World {""}
            <EmphasizedText>Hear? </EmphasizedText>
          </MainText>
          <PaperHeadText>With Tublian, make sure they do.</PaperHeadText>
        </>
      );
    case 2:
      return (
        <>
          <h1>Turn Side Gigs to Steady Income</h1>
          <PaperHeadText>Build portfolio, win projects, earn more</PaperHeadText>
        </>
      );
    case 3:
      return (
        <>
          <MainText>
            In Tomorrow's World, Online Credibility is {""}
            <EmphasizedText>Capital </EmphasizedText>
          </MainText>
          <PaperHeadText>Accumulate yours with Tublian</PaperHeadText>
        </>
      );
    case 4:
      return (
        <>
          <MainText>
            The Internet Remembers Great {""}
            <EmphasizedText>Developers </EmphasizedText>
          </MainText>
          <PaperHeadText>
            Let Tublian elevate your developer story.
          </PaperHeadText>
        </>
      );
    case 5:
      return (
        <>
          <MainText>
            Great Code Deserves an {""}
            <EmphasizedText>Audience</EmphasizedText>
          </MainText>
          <PaperHeadText>
            With Tublian, ensure your work doesn't go unnoticed.
          </PaperHeadText>
        </>
      );
    default:
      return (
        <>
          <MainText>Scale your software developer career</MainText>
          <PaperHeadText>
            The only platform you need to build your career and be seen
          </PaperHeadText>
        </>
      );
  }
};

const MainTopSec = () => {
  const signInCode = RefCode.code();

  // const messageId = Math.floor(Math.random() * 6);
  const messageId = 2;

  const handleGithubSignIn = () => {
    logEvent("LandingPage", "public-view", {
      sourceComponent: "joinBtn-mainTopSec",
      messageId: messageId,
    });
    GithubClient.signIn(signInCode);
    return false;
  };

  return (
    <Root>
      <MainContainer>
        <section>
          {/* <div className={classes.switchBtn}>
            <PageSwitch buttonIndex={1} />
          </div> */}
          <MainText>
            <HeroMessage messageId={messageId} />
            <CtaBtnBox onClick={handleGithubSignIn}>
              <CtaButton>{githubSvg}Join with Github</CtaButton>
            </CtaBtnBox>
          </MainText>
        </section>
      </MainContainer>
    </Root>
  );
};

export default MainTopSec;

const Root = styled("div")(({ theme }) => ({
  maxWidth: "1200px",
  margin: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh - 64px)",
  [theme.breakpoints.down(900)]: {
    paddingTop: 16,
  },
  [theme.breakpoints.down(720)]: {
    paddingRight: 16,
    paddingLeft: 16,
  },
}));

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  [theme.breakpoints.down(900)]: {
    paddingTop: 76,
  },
}));

const EmphasizedText = styled("span")(({ theme }) => ({
  color: "#EFB81F",
  textDecorationThickness: "0.3vw",
  [theme.breakpoints.down(600)]: {
    fontSize: "2rem",
  },
}));

const CtaButton = styled("button")(({ theme }) => ({
  background: "linear-gradient(to bottom, #FDD649 0%, #97802C 100%)",
  padding: "8px 32px",
  color: "#FEFEFE",
  borderRadius: 20,
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: 40,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  "&:hover": {
    background: "#97802C",
  },
  [theme.breakpoints.down(720)]: {
    justifyContent: "center",
    marginTop: 0,
  },
}));

const MainText = styled("div")(({ theme }) => ({
  width: "874px",
  margin: "auto",
  [theme.breakpoints.down(600)]: {
    width: "100%",
  },
  [theme.breakpoints.down(900)]: {
    maxWidth: "570px",
    width: "100%",
  },
  "&>h1": {
    color: "#fefefe",
    fontWeight: 700,
    fontSize: "4rem",
    textAlign: "center",
    lineHeight: "5.423125rem",
    textTransform: "capitalize",
    width: "760px",
    margin: "auto",
    [theme.breakpoints.down(1025)]: {
      width: "570px",
      fontSize: "3rem",
      lineHeight: "3.828125rem",
    },
    [theme.breakpoints.down(900)]: {
      width: "100%",
    },
    [theme.breakpoints.down(720)]: {
      fontSize: "2.25rem",
      lineHeight: "3.05875rem",
      width: "100%",
    },
  },
}));

const CtaBtnBox = styled("div")(({ theme }) => ({
  margin: "4px 0 0px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down(1025)]: {
    marginBottom: "112px",
  },
  [theme.breakpoints.down(720)]: {
    marginTop: "20px",
    marginBottom: "60px",
    width: "100%",
  },
}));

const PaperHeadText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: "2rem",
  margin: "auto",
  textAlign: "center",
  marginTop: 20,
  color: "#fefefe",
  // width: 407,

  [theme.breakpoints.down(720)]: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    width: 300,
    marginTop: 16,
  },

  [theme.breakpoints.down(320)]: {
    width: "100%",
  },
}));
