import { FC, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Box, Chip, Typography, styled } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";

import { useLearnContext } from "./Course/LearnContext";
import CustomButton from "../Common/CustomButton";
import OverlayModal from "../Common/OverlayModal";
import StatusNotification from "../Common/StatusNotification";
import FeedBack from "../Common/FeedBack";
import { logEvent } from "../../libs/amplitude";
import { userProfile } from "../../libs/UserProfile";
import LearningRoadmapEndpoint from "../../libs/LearningRoadmapEndpoint";
import {
  ILearningProgress,
  LearningView,
  RoadmapListItem,
} from "./Common/LearningTypes";
import TextUtils from "../../libs/TextUtils";
import ProfileEndpoint from "../../libs/ProfileEndpoint";
import NewCustomTabs from "../Common/NewCustomTabs";
import RoadmapPhaseView from "./Course/RoadmapPhaseView";
import RecentRoadmapsView from "./Course/RecentRoadmapsView";
import helperFunctions from "../../libs/helperFunctions";
import CustomShareBtn from "../Common/CustomShareBtn.tsx";
import { closeSvg } from "../UserHome/Base/SVG";
import DeleteRoadmap from "./DeleteRoadmap";
import CourseCertificate from "./Course/CourseCertificate/CourseCertificate";
// import CustomToast from "../Common/CustomToast";

const RoadmapView: FC<IProps> = ({ isPublic, type }) => {
  const history = useHistory();
  const { reencodeRoadmapId } = helperFunctions;
  const { capsFirstLetter } = TextUtils;
  let { roadmapId } = useParams<{ roadmapId: string }>();

  const [phaseProgress, setPhaseProgress] = useState<
    IPhaseProgress | undefined
  >(undefined);

  const [loadingRecents, setLoadingRecents] = useState<
    "not_loaded" | "loading" | "loaded"
  >("not_loaded");

  roadmapId = reencodeRoadmapId(roadmapId) || "";

  const {
    error,
    roadmapPhasesList,
    activeRoadmapTab,
    loadingState,
    popupStateData,
    recentInteractedRoadmaps,
    setError,
    setRoadmapPhasesList,
    setActiveRoadmapTab,
    updateLoadingState,
    // setCredits,
    // getCreditStatus,
    updatePopupStateData,
    setPopupStateData,
    fetchInteractedRoadmapPhases,
    myParam
  } = useLearnContext();

  const userName = userProfile(localStorage).userId();

  const handleBack = () => {
    if (window.history.length > 1) {
      // history.goBack();
      history.push("/learn");
    }
  };

  const handleTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updatePopupStateData("feedbackData", {
      ...popupStateData.feedbackData,
      feedbackMsg: e.target.value,
    });
  };

  const handleFeedback = () => {
    // console.log("data", popupStateData);
    const { feedbackData, course } = popupStateData;
    const { reaction, feedbackMsg } = feedbackData;

    const user = isPublic ? "public" : userName;

    logEvent("8020", user, {
      action: "feedback",
      reaction, // liked or disliked
      feedbackMsg, // feedback message
      course, // phase liked
    });

    setPopupStateData({
      isActive: false,
      feedbackData: {},
      popupMode: "",
    });
  };

  const handleDelete = (roadmap: RoadmapListItem | undefined) => {
    if (!roadmap) {
      setError({
        error: true,
        errorMsg: "Error encountered while deleting roadmap, please try again.",
      });
      return;
    }

    setPopupStateData({
      isActive: true,
      roadmap,
      feedbackData: {},
      popupMode: "delete",
    });
  };

  useEffect(() => {
    const fetchAllPhaseProgress = async () => {
      try {
        const phaseIds = roadmapPhasesList?.roadmap?.phases.map(
          (phase) => phase.id
        );
        if (phaseIds) {
          const responses = await Promise.all(
            phaseIds.map((phaseId) => ProfileEndpoint.getPhaseStatus(phaseId))
          );
          // console.log("🚀 ~ fetchAllPhaseProgress ~ responses:", responses);
          if (responses?.length > 0) {
            const phaseProgressData = Object.fromEntries(
              responses.map((response, index) => [
                phaseIds[index],
                response?.result,
              ])
            );
            // console.log("🚀 ~", phaseProgressData);
            setPhaseProgress(phaseProgressData);
          }
        }
      } catch (error) {
        console.error("Error fetching phase progress:", error);
      }
    };

    if (roadmapPhasesList && !phaseProgress && !loadingState.creatingRoadmaps) {
      fetchAllPhaseProgress();
    }
  }, [roadmapPhasesList, phaseProgress, loadingState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          roadmapId?.length > 0 &&
          roadmapPhasesList?.roadmapId !== roadmapId // don't refetch
        ) {
          updateLoadingState("loadingRoadmaps", true);

          // console.log("🚀 ~ roadmapId:", roadmapId);
          const res = await LearningRoadmapEndpoint.getRoadmap(
            roadmapId,
            userName
          );
          // console.log("🚀 ~ fetchData ~ res:", res);
          setRoadmapPhasesList(res);
          updateLoadingState("loadingRoadmaps", false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        updateLoadingState("loadingRoadmaps", false);
        setError({
          error: true,
          errorMsg:
            "Error encountered while loading roadmap, please try again.",
        });
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [roadmapId]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (!isPublic) {
  //         await getCreditStatus();
  //       } else {
  //         setCredits(0);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();

  //   // eslint-disable-next-line
  // }, [isPublic]);

  useEffect(() => {
    if (
      !loadingState.creatingRoadmaps &&
      loadingRecents !== "loaded" &&
      recentInteractedRoadmaps?.[0]?.recentPathway?.roadmapId !== roadmapId
    ) {
      fetchInteractedRoadmapPhases(roadmapId, setLoadingRecents, true);
    }
    //eslint-disable-next-line
  }, [roadmapId, loadingState.creatingRoadmaps, recentInteractedRoadmaps]);
  // console.log("🚀 ~ recentInteractedRoadmaps:", recentInteractedRoadmaps);

  useEffect(() => {
    if (myParam) {
      setActiveRoadmapTab(3);
    }
    // eslint-disable-next-line
  }, [myParam]);

  const showRecentPhases =
    Array.isArray(recentInteractedRoadmaps) &&
    recentInteractedRoadmaps.length > 0 &&
    recentInteractedRoadmaps[0].phase &&
    true;

  const queryType = roadmapPhasesList?.roadmap?.queryType;
  const [abridgedPrompt, setAbridgedPrompt] = useState<boolean>(
    queryType === "internship"
  );

  const handleTogglePrompt = () => {
    setAbridgedPrompt(!abridgedPrompt);
  };

  return (
    <PrimaryColumn>
      {!isPublic && (
        <BackBox>
          <CustomButton size="small" type="tertiary" onClick={handleBack}>
            <ArrowBackOutlined />
            Back
          </CustomButton>
        </BackBox>
      )}
      <OverlayModal isPreviewOpen={popupStateData.isActive || error.error}>
        {error.error && (
          <StatusNotification
            status="error"
            statusMessage={error.errorMsg}
            cancelBtnName="Close"
            cancelBtnHandleClick={() =>
              setError({ error: false, errorMsg: "" })
            }
          />
        )}

        {popupStateData.isActive && popupStateData.popupMode === "delete" && (
          <DeleteRoadmap redirectFxn={() => history.push("/learn")} />
        )}
        {popupStateData.popupMode === "feedback" && (
          <FeedBack
            title="Additional Feedback"
            inputPlaceholder={`What do you like about this phase?`}
            textareaValue={popupStateData?.feedbackData?.feedbackMsg || ""}
            cancelBtnName="Close"
            proceedBtnName="Send Feedback"
            textareaHandler={handleTextarea}
            cancelBtnHandleClick={handleFeedback}
            proceedBtnHandleClick={handleFeedback}
          />
        )}
      </OverlayModal>

      <PromptWrap>
        <PromptCardHeader>
          <StyledChip label="Prompt" variant="outlined" />
          <PromptCardActions>
            <Box
              className="closeBtn"
              onClick={() => handleDelete(roadmapPhasesList)}
            >
              {closeSvg}
            </Box>
            <Box className="shareBtn">
              <CustomShareBtn
                url={`https://www.tublian.com/learn/${roadmapPhasesList?.roadmapId}`}
                title={roadmapPhasesList?.roadmap?.learningPrompt}
                description={`I found this learning roadmap and I thought you would like it`}
              />
            </Box>
          </PromptCardActions>
        </PromptCardHeader>

        <StyledPrompt abridged={abridgedPrompt}>
          {capsFirstLetter(roadmapPhasesList?.roadmap?.learningPrompt)}
        </StyledPrompt>
        {queryType === "internship" && (
          <CustomButton type="tertiary" onClick={handleTogglePrompt}>
            {abridgedPrompt ? "Show More" : "Show Less"}
          </CustomButton>
        )}
      </PromptWrap>

      <NewCustomTabs
        defaultValue={activeRoadmapTab}
        tabSwitchStyles={{ marginBottom: "20px" }}
        onTabChange={(val: number) => {
          if (val === 3) {
            history.push(`/learn/${userName}/${roadmapId}?certificate=8020`);
          } else {
            history.push(`/learn/${userName}/${roadmapId}`); 
            setActiveRoadmapTab(val as 1 | 2);
          }
        }}
        tabsData={[
          {
            value: 1,
            show: showRecentPhases,
            label: <Typography>Resume Learning</Typography>,
            content: <RecentRoadmapsView />,
          },
          {
            value: 2,
            show: true,
            label: <Typography>Phases</Typography>,
            children: (
              <>
                {!loadingState.loadingRoadmaps && (
                  <StyledCount>
                    {roadmapPhasesList?.roadmap?.phases?.length || 0}
                  </StyledCount>
                )}
              </>
            ),
            content: (
              <RoadmapPhaseView
                roadmapId={roadmapId}
                phaseProgress={phaseProgress}
              />
            ),
          },
          {
            value: 3,
            show: true,
            label: (
              <Link
                to={`/learn/${userName}/${roadmapId}?certificate=8020`}
                style={{
                  color: "#888888",
                  textDecoration: "none",
                }}
              >
                <Typography>Certificate</Typography>
              </Link>
            ),
            content: myParam && <CourseCertificate />,
          },
        ]}
      />
    </PrimaryColumn>
  );
};

export default RoadmapView;

interface IProps {
  isPublic: boolean;
  type: LearningView;
}

interface IPhaseProgress {
  [id: string]: ILearningProgress;
}

const PrimaryColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  marginBottom: "16px",
});

const BackBox = styled(Box)({
  width: "100%",
  justifySelf: "flex-start",
  marginBottom: "28px",
  "& button": {
    paddingLeft: "0px !important",
  },
});

const StyledCount = styled("span")({
  backgroundColor: "#F26663",
  color: "#FEFEFE",
  padding: "2px 6px",
  borderRadius: 5,
  marginLeft: 5,
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
});

const PromptWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  border: "1px solid #1E1E1E",
  borderRadius: "15px",
  padding: "16px 20px",
  margin: "0 0 12px",

  "&:hover": {
    "& .shareBtn": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    "& .closeBtn": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  },
});

const PromptCardHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const PromptCardActions = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "#FEFEFE",
  gap: "20px",
  "& .shareBtn": {
    display: "none",
    width: "18px",
    height: "18px",
    "& svg": {
      color: "#FEFEFE",
    },
  },
  "& .closeBtn": {
    display: "none",
    width: "18px",
    height: "18px",
    "& svg": {
      width: "18px",
      height: "18px",
    },
  },
  [theme.breakpoints.down(700)]: {
    "& .shareBtn": {
      display: "flex",
      alignItems: "center",
    },
    "& .closeBtn": {
      display: "flex",
      alignItems: "center",
    },
    "& p": {
      color: "#FEFEFE",
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "1.25rem",
      width: "65%",
    },
  },
}));

const StyledPrompt = styled(Typography)<{ abridged: boolean }>(
  ({ abridged }) => ({
    // color: "#FEFEFE",
    color: "#CFCFCF",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.625rem",

    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: abridged ? 3 : "none",
    WebkitBoxOrient: "vertical",
    textOverflow: abridged ? "clip" : "unset",
  })
);

const StyledChip = styled(Chip)({
  background: "#1E1E1E",
  padding: "4px 6px",
  border: "none",
  fontSize: "0.75rem",
  lineHeight: "1rem",
  fontWeight: 400,
});
