import { useEffect, useState } from "react";
// import Header from "../components/Landing/Header";
import MainTopSec from "../components/Landing/DevelopersPage/MainTopSec";
import { styled } from "@mui/system";
import heroImg from "../images/landing/discoverOppsHeroImg.png";
import coinImg from "../images/landing/coin.png";
import flashBackground from "../images/landing/flash.png";
import rocketBackground from "../images/landing/rocketBackground.png";
import { logEvent } from "../libs/amplitude";
import Footer from "../components/Landing/Footer";
import OurPlan from "../components/Landing/DevelopersPage/OurPlan";
import OpenSourceJourney from "../components/Landing/DevelopersPage/OpenSourceJourney";
// import VideoSec from "../components/Landing/DevelopersPage/VideoSec";
// import DevsTestimonial from "../components/Landing/DevelopersPage/DevsTestimonial";
import VideoSec from "../components/Landing/DevelopersPage/VideoSec";
import Features from "../components/Landing/DevelopersPage/Features";
import NewHeader from "../components/Landing/NewHeader";
// import DevsTestimonial from "../components/Landing/DevelopersPage/DevsTestimonial";
import BizDev from "../components/Landing/DevelopersPage/BizDev";
import TosAgent from "../components/Landing/DevelopersPage/TosAgent";
import { useTublianOsContext } from "../components/Landing/TublianOs/TublianOsContext";
import { Box } from "@mui/material";
import TublianOsWaitlistForm from "../components/Landing/TublianOs/TublianOsWaitlist/TublianOsWaitlistForm";
import TublianOsWaitlistStatus from "../components/Landing/TublianOs/TublianOsWaitlist/TublianOsWaitlistStatus";

const LandingPage = () => {
  const [solidNav, setSolidNav] = useState(false);
  const { waitlistDetails, tosStatusPopup, setTosStatusPopup } =
    useTublianOsContext();
  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        logEvent("LandingPage", "public-view", {
          sourceComponent: `scrolledTo-${entry.target.id}`,
        });
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    const sourcecomponentIds = [
      "mainTopSec",
      "videoSec",
      "ourPlanSec",
      "becomeFutureReadySec",
      "bizDevSec",
      "tosAgentSec",
      "openSourceJourneySec",
    ];

    sourcecomponentIds.forEach((id) => {
      const target = document.getElementById(id);
      if (target) {
        observer.observe(target);
      }
    });

    return () => {
      sourcecomponentIds.forEach((id) => {
        const target = document.getElementById(id);
        if (target) {
          observer.unobserve(target);
        }
      });
    };
  }, []);

  useEffect(() => {
    const changeNavToSolid = () => {
      if (window.scrollY >= 64) {
        setSolidNav(true);
      } else {
        setSolidNav(false);
      }
    };

    window.addEventListener("scroll", changeNavToSolid);
    return () => {
      window.removeEventListener("scroll", changeNavToSolid);
    };
  }, []);

  return (
    <Landing>
      {waitlistDetails.isActive && (
        <ModalOverlay>
          <TublianOsWaitlistForm />
        </ModalOverlay>
      )}
      {tosStatusPopup.isActive && (
        <ModalOverlay>
          <TublianOsWaitlistStatus
            status={tosStatusPopup.status}
            message={tosStatusPopup?.msg}
            setPopupData={setTosStatusPopup}
            popupData={tosStatusPopup}
          />
        </ModalOverlay>
      )}
      {/* <Blur style={{ backgroundColor: "rgba(217, 217, 217, 1)" }}></Blur> */}
      <TopSection>
        <HeaderContainer solidNav={solidNav}>
          <NewHeader />
        </HeaderContainer>
        <div id="mainTopSec">
          <MainTopSec />
        </div>
        <ContainerVid>
          <div id="videoSec">
            <VideoSec />
          </div>
        </ContainerVid>
      </TopSection>

      <Container>
        <div id="ourPlanSec">
          <OurPlan />
        </div>
      </Container>
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        {/* <Image src={glassEffect} alt="" /> */}
        <FutureReadyCon>
          <div id="becomeFutureReadySec">
            <Features />
          </div>
        </FutureReadyCon>
      </div>

      <BizDevCon>
        <div id="bizDevSec">
          <BizDev />
        </div>
      </BizDevCon>

      <TosAgentCon>
        <div id="tosAgentSec">
          <TosAgent />
        </div>
      </TosAgentCon>

      {/* <div id="devsTestimonialSec">
        <DevsTestimonial />
      </div> */}
      <ContainerOS>
        <div id="openSourceJourneySec">
          <OpenSourceJourney />
        </div>
      </ContainerOS>
      <FooterCon>
        <Footer />
      </FooterCon>
    </Landing>
  );
};

export default LandingPage;

const Landing = styled("div")(({ theme }) => ({
  scrollBehavior: "smooth",
  backgroundColor: "#111111",
  position: "relative",
  overflow: "hidden",
  width: "100%",
}));

const Container = styled("div")(({ theme }) => ({
  maxWidth: 1440,
  position: "relative",
  width: "calc(100% - 128px)",
  margin: "auto",
  [theme.breakpoints.down(1025)]: {
    width: "calc(100% - 81px)",
  },
  [theme.breakpoints.down(900)]: {
    width: "100%",
  },
  [theme.breakpoints.down(720)]: {
    padding: "0 16px",
  },
}));

const FutureReadyCon = styled("div")(({ theme }) => ({
  background: `url(${rocketBackground}) no-repeat center 0px`,
  backgroundSize: "auto",
  backgroundBlendMode: "multiply",
  backgroundPosition: `left -0px top 125px`,
  position: "relative",
  width: "100%",
  maxWidth: 1400,
  margin: "auto",
  [theme.breakpoints.down(800)]: {
    padding: "0 16px",
    background: "none",
  },
}));

const TosAgentCon = styled("div")(({ theme }) => ({
  background: `url(${flashBackground}) no-repeat center 0px`,
  backgroundSize: "auto",
  backgroundBlendMode: "multiply",
  backgroundPosition: `left -0px top 20px`,
  position: "relative",
  width: "100%",
  maxWidth: 1400,
  margin: "auto",
  [theme.breakpoints.down(800)]: {
    padding: "0 16px",
    background: "none",
  },
}));

const BizDevCon = styled("div")(({ theme }) => ({
  background: `url(${coinImg}) no-repeat center 0px`,
  backgroundSize: "auto",
  backgroundBlendMode: "multiply",
  backgroundPosition: `right -0px top 110px`,
  position: "relative",
  maxWidth: 1400,
  margin: "auto",
  [theme.breakpoints.down(1025)]: {
    width: "100%",
  },
  [theme.breakpoints.down(800)]: {
    padding: "0 16px",
    background: "none",
  },
}));

const ContainerVid = styled(Container)(({ theme }) => ({
  width: "calc(100% - 128px)",
  [theme.breakpoints.down(1025)]: {
    width: "calc(100% - 128px)",
  },
  [theme.breakpoints.down(900)]: {
    padding: 16,
    width: "100%",
  },
}));

const ContainerOS = styled("div")(({ theme }) => ({
  maxWidth: 1200,
  position: "relative",
  width: "calc(100% - 112px)",
  margin: "auto",
  [theme.breakpoints.down(800)]: {
    width: "100%",
  },
}));

const TopSection = styled("div")(({ theme }) => ({
  background: `url(${heroImg}) no-repeat top`,
  position: "relative",
  width: "100%",
  maxWidth: 1400,
  margin: "auto",
  [theme.breakpoints.down(600)]: {
    backgroundSize: "100 100%",
  },
}));

const HeaderContainer = styled(Box)<{ solidNav: boolean }>(({ solidNav }) => ({
  margin: "auto",
  maxWidth: "1440px",
  position: "fixed",
  width: "100%",
  top: 0,
  background: solidNav ? "rgba(17, 17, 17, 0.05)" : "none",
  backdropFilter: solidNav ? "blur(20px) !important" : "blur(0px) !important",
  zIndex: 1000,
}));

const FooterCon = styled("div")({
  zIndex: "999",
  maxWidth: 1400,
  margin: "auto",
});

const ModalOverlay = styled(Box)({
  fontStyle: "normal",
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  background: "rgba(18, 18, 18, 0.5)",
  WebkitBackdropFilter: "blur(20px) !important",
  backdropFilter: "blur(20px) !important",
  zIndex: 100,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});
