import { Box, Button, styled, Tab, Tabs, Typography } from "@mui/material";
import earnMoreBtn from "../../../images/landing/earnMoreBtn.png";
import { Link } from "react-router-dom";
import {
  freelanceProjects,
  nichesSvg,
  proposalSvg,
  smallArrowRightSvg,
} from "../../UserHome/Base/SVG";
import { useEffect, useState } from "react";
import freelanceImg from "../../../images/landing/freelanceImg.png";
import proposalImg from "../../../images/landing/proposalImg.png";
import nichesImg from "../../../images/landing/nichesImg.png";
import { useTublianOsContext } from "../TublianOs/TublianOsContext";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            paddingTop: "28px",
            height: "fit-content",
            width: "fit-content",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const BizDev = () => {
  const [value, setValue] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const { handleOpenWaitlistForm } = useTublianOsContext();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handlePause = () => {
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsPaused(false);
  };

  useEffect(() => {
    if (isPaused) return;

    const tabCount = 3;
    const interval = setInterval(() => {
      setValue((prevValue) => (prevValue + 1) % tabCount);
    }, 3000);

    return () => clearInterval(interval);
  }, [isPaused]);

  return (
    <BizDevContainer>
      <img src={earnMoreBtn} alt="earn more" />
      <Header>
        <h2>Win Projects That Pay Better</h2>
        <BizDevSubtext>
          Meet BizDev Agent, your AI partner who works 24/7 to find opportunities and clients, optimize your rates, write proposals, and help you win more projects.
        </BizDevSubtext>
      </Header>
      <ButtonWrapper>
        <Button
          style={{
            color: "#fefefe",
            padding: "8px 16px",
            borderRadius: "20px",
            background: `linear-gradient(#16D977, #0C733F) padding-box,
               linear-gradient(109.15deg, #1AFF8C 0%, #1AFF8C 86.08%) border-box`,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            fontWeight: 500,
          }}
          onClick={handleOpenWaitlistForm}
        >
          Join waitlist
        </Button>
        <LearnMoreLink>
          <Link to="/discover-opportunities">
            Learn more
            <span>{smallArrowRightSvg}</span>
          </Link>
        </LearnMoreLink>
      </ButtonWrapper>
      <div
        className="tabs"
        onMouseEnter={handlePause}
        onMouseLeave={handleResume}
      >
        <StyledTabs
          value={value}
          onChange={(event, newValue) => {
            handlePause();
            handleChange(event, newValue);
          }}
          aria-label=""
          orientation="horizontal"
        >
          <StyledTab
            icon={freelanceProjects}
            iconPosition="start"
            label="Find Perfect Projects"
          />
          <StyledTab
            icon={nichesSvg}
            iconPosition="start"
            label="Own Your Niche"
          />
          <StyledTab
            icon={proposalSvg}
            iconPosition="start"
            label="Win More Projects"
          />
        </StyledTabs>
      </div>
      <Box sx={{}}>
        <CustomTabPanel value={value} index={0}>
          <BizDevImage src={freelanceImg} alt="Find Perfect Projects" />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <BizDevImage src={nichesImg} alt="Own Your Niche" />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <BizDevImage src={proposalImg} alt="Win More Projects" />
        </CustomTabPanel>
      </Box>
    </BizDevContainer>
  );
};

export default BizDev;

const BizDevContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingBottom: "160px",
  height: "fit-content",
  width: "calc(100% - 128px)",
  margin: "auto",
  [theme.breakpoints.down(1025)]: {
    width: "calc(100% - 81px)",
  },
  [theme.breakpoints.down(720)]: {
    width: "100%",
    paddingBottom: "80px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  // paddingTop: 80,
  [theme.breakpoints.down(800)]: {
    paddingTop: 20,
  },
  "&>button": {
    color: "#fefefe",
    background: "linear-gradient(to bottom, #222325 0%, #383A3D 100%)",
    padding: "4px 20px",
    display: "flex",
    gap: 8,
    alignItems: "center",
    border: "1px solid #222325",
    borderRadius: 20,
  },
  "&>h2": {
    margin: "auto",
    width: 831,
    fontSize: "3rem",
    textAlign: "center",
    lineHeight: "4.5rem",
    color: "#fefefe",
    fontWeight: 700,
    padding: "0px 0 20px 0",
    [theme.breakpoints.down(1025)]: {
      width: "663px",
      fontSize: "2.5rem",
      lineHeight: "3.19rem",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: "1.875rem",
      lineHeight: "2.8125rem",
      width: "100%",
    },
  },
  "&>p": {
    width: 739,
    margin: "auto",
    textAlign: "center",
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: "2rem",
    color: "#fefefe",
    paddingBottom: 20,

    [theme.breakpoints.down(1025)]: {
      width: "663px",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      width: "100%",
    },
  },
}));

const BizDevSubtext = styled(Typography)(({ theme }) => ({
  width: 511,
  marginBottom: 100,
  [theme.breakpoints.down(900)]: {
    fontSize: "1.875rem",
    lineHeight: "2.8125rem",
    width: 240,
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  marginBottom: "40px",
  [theme.breakpoints.down(500)]: {
    flexDirection: "column",
  },
}));

const LearnMoreLink = styled(Box)(({ theme }) => ({
  "& a": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: 8,
    textDecoration: "none",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    color: "#1AFF8C",
    padding: "8px 16px",
    backgroundColor: "#203E2F",
    borderRadius: "20px",

    "& span": {
      display: "flex",
    },

    "&:hover": {
      color: "#fefefe",
      textDecoration: "underline",
    },
  },
}));

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  orientation: "horizontal" | "vertical" | undefined;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    display: "none",
  },
});

interface StyledTabProps {
  label: string;
  icon: React.ReactElement; // Correct type for React elements
  iconPosition: "start" | "end" | "top" | "bottom"; // To match Material UI's iconPosition
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  color: "#b7b7b7",
  paddingTop: "8px",
  paddingLeft: "8px",
  paddingBottom: "8px",
  paddingRight: "16px",
  minHeight: "fit-content",
  "&>button": {
    fontWeight: 700,
    fontSize: "1rem !important",
    lineHeight: "1.5rem",
  },
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: "#19191B",
    border: "1px solid #222325",
    borderRadius: "10px",
    padding: "8px 16px",

    "& .MuiTab-iconWrapper": {
      color: "#0087FF",
    },
  },

  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const BizDevImage = styled("img")(({ theme }) => ({
  height: "689px",
  width: "868px",
  [theme.breakpoints.down(920)]: {
    width: "100%",
  },
  [theme.breakpoints.down(500)]: {
    height: "284px",
    minHeight: "200px",
  },
}));
