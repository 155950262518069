import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import successIcon from "../../images/success-icon.png";
import errorIcon from "../../images/error-icon.png";
import { Theme } from "@mui/material";

interface Props {
  status: string;
  statusMessage: string;
  cancelBtnName?: string | boolean;
  proceedBtnName?: string;
  setShowStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  cancelBtnHandleClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  proceedBtnHandleClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  statusWrap: {
    border: "1px solid #414141",
    backgroundColor: "#1E1E1E",
    borderRadius: 20,
    width: 668,
    height: 279,
    color: "#FEFEFE",
    position: "relative",
    [theme.breakpoints.down(720)]: {
      width: 356,
      height: 279,
    },
  },
  statusHeader: {
    borderBottom: "1px solid #292929",
    padding: 20,
    display: "flex",
    alignItems: "center",
    gap: 28,
    fontFamily: "Space Grotesk",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "32px",
  },
  statusBody: {
    padding: 20,
  },
  statusFooter: {
    paddingRight: 20,
    paddingBottom: 21,
    display: "flex",
    justifyContent: "flex-end",
    fontStyle: "normal",
    color: "#B7B7B7",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    gap: "20px",
    position: "absolute",
    bottom: 0,
    right: 0,
    [theme.breakpoints.down(720)]: {
      gap: 26,
    },
  },
  closeIcon: {
    color: "green",
  },
  buttonNormal: {
    backgroundColor: "#FDD649",
    padding: "12px 32px",
    fontFamily: "Space Grotesk",
    color: "#121212",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "30px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FEE38B",
    },
  },
  buttonTransparent: {
    backgroundColor: "transparent",
    color: "#FDD649",
    padding: "12px 32px",
    fontFamily: "Space Grotesk",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#25231A",
      borderRadius: "30px",
      color: "#FEE38B",
    },
  },
}));

const StatusNotification: FC<Props> = ({
  status,
  statusMessage,
  cancelBtnName,
  proceedBtnName,
  cancelBtnHandleClick,
  proceedBtnHandleClick,
  setShowStatus
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.statusWrap}>
        <div className={classes.statusHeader}>
          {status === "success" ? (
            <img src={successIcon} alt="success" />
          ) : status === "error" ? (
            <img src={errorIcon} alt="error" />
          ) : null}
          {status[0].toUpperCase() + status.slice(1)}
        </div>
        <div className={classes.statusBody}>
          <p>{statusMessage}</p>
        </div>
        <div className={classes.statusFooter}>
          {cancelBtnName && (
            <button
              className={classes.buttonTransparent}
              onClick={cancelBtnHandleClick}
            >
              {cancelBtnName}
            </button>
          )}
          {proceedBtnName && (
            <button
              className={classes.buttonNormal}
              onClick={proceedBtnHandleClick}
            >
              {proceedBtnName}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default StatusNotification;
