import {
  FC,
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
// import { userProfile } from "../../libs/UserProfile";
import ProfileEndpoint from "../../../libs/ProfileEndpoint";
import {
  IGitHubIssueForList,
  ILightDetailedProject,
  Repository,
} from "../types";
import FT from "../../../libs/FT";
import { userProfile } from "../../../libs/UserProfile";
import {
  logEvent,
  logGithubIssueEvent,
  logGithubProjectEvent,
} from "../../../libs/amplitude";
import { GithubIssue } from "../../../models/github-issue";
import { GithubRepo } from "../../../models/github-repo";
import { saveUserProjectEvent } from "../../Common/Analytics/UserEventsAPI";
import { weeklyPathwayData } from "../../AiOsInternshipPathway/AiOsPathwayData";
import { useHistory } from "react-router-dom";
import { useChatBotContext } from "../../Context/ChatBotContext";

// interface PathwayProgress {
//   pathwayId: string | null;
//   progress: { [key: string]: string };
//   score: number;
//   startedAt: Date;
//   updatedAt: Date;
//   userId: string;
//   status: string;
// }

interface ProjectsContextType {
  popularProjects: Repository[];
  recentProjects: Repository[];
  activeProjectCategory: string;
  curatedProjects: Repository[];
  curatedprojectsPerPage: number;
  loadingTrending: boolean;
  loadingRecents: boolean;
  activeSearchQuery: string;
  searchResults: Repository[];
  searchStatus: string;
  query: string;
  githubIssues: GithubIssue[];
  loadingIssues: boolean;
  loadingIssueList: boolean;
  loadingCuratedProjects: boolean;
  issueList: IGitHubIssueForList[];
  repoForIssues?: Repository;
  showRelatedIssues: boolean;
  selectedProject: Partial<Repository> | undefined;
  repoIdForIssueData: string | undefined;
  pathwayProgress: any;
  pathwayTaskProgress: {
    [key: string]: { status: boolean; completedTasks: any[]; openTasks: any[] };
  };
  currentPathwayWeek: number;
  setQuery: Dispatch<SetStateAction<string>>;
  setLoadingTrending: Dispatch<SetStateAction<boolean>>;
  setRecentProjects: Dispatch<SetStateAction<Repository[]>>;
  setActiveProjectCategory: Dispatch<SetStateAction<string>>;
  setCuratedProjects: Dispatch<SetStateAction<Repository[]>>;
  setCuratedprojectsPerPage: Dispatch<SetStateAction<number>>;
  setLoadingCuratedProjects: Dispatch<SetStateAction<boolean>>;
  setActiveSearchQuery: Dispatch<SetStateAction<string>>;
  setSearchResults: Dispatch<SetStateAction<Repository[]>>;
  setSearchStatus: Dispatch<SetStateAction<string>>;
  setCurrentPathwayWeek: Dispatch<SetStateAction<number>>;
  fetchPopularProjects: () => Promise<void>;
  getRecentProjects: () => Promise<void>;
  handleSearch: (query: string, isPublic: boolean) => Promise<void>;
  fetchGithubIssue: (
    repoId: string,
    issueId: string,
    isPublic: boolean
  ) => Promise<void>;
  setGithubIssues: Dispatch<SetStateAction<GithubIssue[]>>;
  setLoadingIssues: Dispatch<SetStateAction<boolean>>;
  setLoadingIssueList: Dispatch<SetStateAction<boolean>>;
  setIssueList: Dispatch<SetStateAction<IGitHubIssueForList[]>>;
  setRepoForIssues: Dispatch<SetStateAction<Repository | undefined>>;
  fetchGithubIssuesList: (repoId: string) => Promise<void>;
  fetchGithubIssuesListByName: (
    userName: string,
    repoName: string
  ) => Promise<void>;
  fetchCuratedProjects: (category: string, isPublic: boolean) => Promise<void>;
  setShowRelatedIssues: Dispatch<SetStateAction<boolean>>;
  setSelectedProject: Dispatch<SetStateAction<Partial<Repository> | undefined>>;
  setRepoIdForIssueData: Dispatch<SetStateAction<string | undefined>>;
  setPathwayProgress: Dispatch<SetStateAction<any>>;
  setPathwayTaskProgress: Dispatch<
    SetStateAction<{
      [key: string]: {
        status: boolean;
        completedTasks: any[];
        openTasks: any[];
      };
    }>
  >;
  fetchPathwayProgress: (pathwayId: string) => void;
  fetchPathwayTaskProgress: (pathwayId: string) => void;
  updatePathwayProgress: (
    id: string,
    newStatus: "complete" | "incomplete",
    setIsUpdating: Dispatch<SetStateAction<boolean>>,
    pathwayId: string
  ) => void;
}

const ProjectsContext = createContext<ProjectsContextType | undefined>(
  undefined
);

export function useProjectsContext() {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error(
      "useProjectsContext must be used within a ProjectsProvider"
    );
  }
  return context;
}

export const ProjectsProvider: FC = ({ children }) => {
  const userCache = userProfile(localStorage);
  const history = useHistory();
  const { setTublibotExplorerData } = useChatBotContext();
  const [popularProjects, setPopularProjects] = useState<Repository[]>([]);
  const [recentProjects, setRecentProjects] = useState<Repository[]>([]);
  const [activeSearchQuery, setActiveSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Repository[]>([]);
  const [searchStatus, setSearchStatus] = useState("not_searched");
  // Three possible states: not_searched, searching and searched
  const [query, setQuery] = useState("");
  const [githubIssues, setGithubIssues] = useState<GithubIssue[]>([]);
  const [issueList, setIssueList] = useState<IGitHubIssueForList[]>([]);
  const [repoForIssues, setRepoForIssues] = useState<Repository>();
  const [showRelatedIssues, setShowRelatedIssues] = useState(false);
  const [repoIdForIssueData, setRepoIdForIssueData] = useState<
    string | undefined
  >();
  const [selectedProject, setSelectedProject] = useState<Partial<Repository>>();

  const [loadingTrending, setLoadingTrending] = useState(false);
  const [loadingRecents, setLoadingRecents] = useState(false);
  const [loadingIssues, setLoadingIssues] = useState(false);
  const [loadingIssueList, setLoadingIssueList] = useState(false);

  // For Curated Projects
  const [activeProjectCategory, setActiveProjectCategory] = useState("");
  const [loadingCuratedProjects, setLoadingCuratedProjects] = useState(false);
  const [curatedProjects, setCuratedProjects] = useState<Repository[]>([]);
  const [curatedprojectsPerPage, setCuratedprojectsPerPage] = useState(5);

  //For Pathway
  const [pathwayProgress, setPathwayProgress] = useState<any>({});
  const [pathwayTaskProgress, setPathwayTaskProgress] = useState<{
    [key: string]: { status: boolean; completedTasks: any[]; openTasks: any[] };
  }>({
    week1: { status: false, completedTasks: [], openTasks: [] },
    week2: { status: false, completedTasks: [], openTasks: [] },
    week3: { status: false, completedTasks: [], openTasks: [] },
    week4: { status: false, completedTasks: [], openTasks: [] },
  });
  const [currentPathwayWeek, setCurrentPathwayWeek] = useState(0);
  // console.log("pathwayProgress state", pathwayProgress);

  useEffect(() => {
    const completedCount = Object.values(pathwayTaskProgress).filter(
      (weekProgress) => weekProgress.status
    ).length;

    if (completedCount !== 0) setCurrentPathwayWeek(completedCount);
  }, [pathwayTaskProgress]);

  useEffect(() => {
    setTublibotExplorerData({
      loading: false,
      tublibotContext: [],
      messagesList: [],
    });

    // eslint-disable-next-line
  }, []);

  const fetchPopularProjects = async () => {
    setLoadingTrending(true);

    try {
      let bResult = await ProfileEndpoint.getProjectsByCategories(
        ["frontend", "backend", "library", "framework"],
        userCache?.selectedLanguages()
      );
      setPopularProjects(bResult.repos || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTrending(false);
    }
  };

  const getRecentProjects = async () => {
    setLoadingRecents(true);
    const response = await FT.recentlySearchProjects(userCache.userId());
    if (response.ids?.length > 0) {
      const response1 = await ProfileEndpoint.getRepoDetailsById(response.ids);
      setRecentProjects(response1.repos);
    }
    setLoadingRecents(false);
  };

  const handleSearch = useCallback(async (query: string, isPublic: boolean) => {
    setSearchStatus("searching");
    logEvent(
      "GithubProjectSearch",
      isPublic ? "public" : userProfile(localStorage).userId(),
      { query: query }
    );

    const ghHacks = await ProfileEndpoint.getGithubHacksByQuery(query);
    const repoIds = ghHacks?.projects?.map(
      (p: ILightDetailedProject) => p.repoId
    );
    if (repoIds?.length > 0) {
      const ghHacksDetails = await ProfileEndpoint.getRepoDetailsById(repoIds);
      if (ghHacksDetails?.repos?.length > 0) {
        setSearchResults(ghHacksDetails.repos);

        logEvent(
          "GithubProjectSearchResult",
          isPublic ? "public" : userProfile(localStorage).userId(),
          { query: query }
        );
      } else {
        logEvent(
          "GithubProjectSearchZero",
          isPublic ? "public" : userProfile(localStorage).userId(),
          { query: query }
        );
      }
    }
    setSearchStatus("searched");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGithubIssue = async (
    repoId: string,
    issueId: string,
    isPublic: boolean
  ) => {
    setLoadingIssues(true);
    var result = await ProfileEndpoint.getGithubIssue(repoId, issueId);
    if (result.issue) {
      const i = result.issue;
      const repo = GithubRepo.createRepo(i.repo);
      const issue = GithubIssue.createIssue(i, repo);
      setGithubIssues([issue]);
      if (!isPublic) {
        const user = userProfile(localStorage);
        logGithubIssueEvent(issue, user.userId());
      } else {
        logGithubIssueEvent(issue, "public");
      }
    }
    setLoadingIssues(false);
  };

  const fetchGithubIssuesList = async (repoId: string) => {
    setLoadingIssueList(true);
    const response = await ProfileEndpoint.getGithubProjectIssueListById(
      repoId
    );
    setIssueList(response.issues);
    setRepoForIssues(response.repo);
    if (response?.issues?.length === 0) setShowRelatedIssues(true);
    setLoadingIssueList(false);
    if (response?.repo) {
      const repoName = response.repo.name;
      await saveUserProjectEvent(parseInt(repoId), repoName);
      logGithubProjectEvent(
        repoId,
        repoName,
        userProfile(localStorage).userId()
      );
    }
  };

  const fetchGithubIssuesListByName = async (
    userName: string,
    repoName: string
  ) => {
    setLoadingIssueList(true);
    const response = await ProfileEndpoint.getGithubProjectIssueListByName(
      userName,
      repoName
    );
    // console.log("🚀 ~ file: index.tsx:287 ~ response:", response.repo.id);
    if (response?.repo?.id) {
      history.replace(`/project/${response.repo.id}`);
    }
    setLoadingIssueList(false);
  };

  const fetchCuratedProjects = async (category: string, isPublic: boolean) => {
    setLoadingCuratedProjects(true);

    const result = await ProfileEndpoint.getProjectsByCategories(
      [category],
      userCache?.selectedLanguages()
    );
    setCuratedProjects(result.repos);

    logEvent("Curated Projects", isPublic ? "public" : userCache.userId(), {
      category,
    });
    setLoadingCuratedProjects(false);
  };

  const fetchPathwayTaskProgress = async (pathwayId: string) => {
    try {
      const response = await ProfileEndpoint.updatePathwayTaskProgress(
        pathwayId
      );

      // const response = await ProfileEndpoint.getOrCreatePathwayProgress(
      //   pathwayId
      // );

      // console.log("🚀 ~ fetchPathwayTaskProgress ~ response:", response);

      const updatedPathwayTaskProgress = { ...pathwayTaskProgress };
      Object.keys(updatedPathwayTaskProgress).forEach((week) => {
        if (response.result.progress[week]) {
          updatedPathwayTaskProgress[week].status =
            response.result.progress[week].status;
          updatedPathwayTaskProgress[week].completedTasks =
            response.result.progress[week].completedTasks || [];
          updatedPathwayTaskProgress[week].openTasks =
            response.result.progress[week].openTasks || [];
        }
      });
      setPathwayTaskProgress(updatedPathwayTaskProgress);

      // console.log("pathwayTaskProgress", pathwayTaskProgress);
    } catch (error) {
      console.error("Error fetching/updating pathway task progress:", error);
    }
  };

  const fetchPathwayProgress = async (pathwayId: string) => {
    try {
      const response = await ProfileEndpoint.getOrCreatePathwayProgress(
        pathwayId
      );
      // console.log("🚀 ~ file: index.tsx:267 ~  response:", response);

      setPathwayProgress(response.result);

      if (response) {
        const defaultProgress: { [key: string]: string } =
          response?.result?.progress || {};

        // console.log("🚀 ~ index.tsx:274 ~ defaultProgress:", defaultProgress);

        let newIdsAppended = false;

        // Get all new resource ids from hard coded file and default them to incomplete
        for (const weekData of weeklyPathwayData) {
          for (const resource of weekData.learningResources) {
            if (!defaultProgress.hasOwnProperty(resource.id)) {
              // console.log("🚀 ~ file: index.tsx:284 ~ fetchPathwayProgress ~ resource:", resource.id)
              defaultProgress[resource.id] = "incomplete";
              newIdsAppended = true;
            }
          }
        }

        if (newIdsAppended) {
          // Refresh if new IDs is appended
          // console.log("refreshing");
          await ProfileEndpoint.updatePathwayProgress(
            pathwayId,
            "in progress",
            defaultProgress
          );
          await fetchPathwayProgress(pathwayId); // update progress to defaulted progress
        }
      }
    } catch (error) {
      console.error("Error fetching/updating pathway progress:", error);
    }
  };

  const updatePathwayProgress = async (
    id: string,
    newStatus = "complete",
    setIsUpdating: Dispatch<SetStateAction<boolean>>,
    pathwayId: string
  ) => {
    // const resources = weeklyPathwayData[displayWeek - 1].learningResources;
    // const finalObj: { [key: string]: string } = {};
    // for (const { id, status } of resources) {
    //   finalObj[id] = status;
    // }
    // finalObj[id] = newStatus;
    setIsUpdating(true);
    const resources = pathwayProgress.progress;

    const response = await ProfileEndpoint.updatePathwayProgress(
      pathwayId,
      "in progress",
      { ...resources, [id]: newStatus }
    );

    response && (await fetchPathwayProgress(pathwayId));
    setIsUpdating(false);
  };

  const contextValue: ProjectsContextType = {
    popularProjects,
    loadingTrending,
    recentProjects,
    loadingRecents,
    activeSearchQuery,
    searchResults,
    searchStatus,
    query,
    githubIssues,
    loadingIssues,
    issueList,
    repoForIssues,
    showRelatedIssues,
    loadingIssueList,
    selectedProject,
    repoIdForIssueData,
    loadingCuratedProjects,
    activeProjectCategory,
    curatedProjects,
    curatedprojectsPerPage,
    pathwayProgress,
    pathwayTaskProgress,
    currentPathwayWeek,
    setLoadingTrending,
    setRecentProjects,
    setActiveSearchQuery,
    setSearchResults,
    setSearchStatus,
    fetchPopularProjects,
    getRecentProjects,
    setQuery,
    handleSearch,
    fetchGithubIssue,
    setGithubIssues,
    setLoadingIssues,
    setIssueList,
    setRepoForIssues,
    setShowRelatedIssues,
    fetchGithubIssuesList,
    fetchGithubIssuesListByName,
    setLoadingIssueList,
    setSelectedProject,
    setRepoIdForIssueData,
    setLoadingCuratedProjects,
    setActiveProjectCategory,
    setCuratedProjects,
    setCuratedprojectsPerPage,
    fetchCuratedProjects,
    setPathwayProgress,
    setPathwayTaskProgress,
    fetchPathwayProgress,
    fetchPathwayTaskProgress,
    updatePathwayProgress,
    setCurrentPathwayWeek,
  };

  return (
    <ProjectsContext.Provider value={contextValue}>
      {children}
    </ProjectsContext.Provider>
  );
};
