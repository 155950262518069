import { styled, Typography, Box } from "@mui/material";
import amazon from "../../../images/landing/amazon.svg";
import discord from "../../../images/landing/discordCompany.svg";
import linkedin from "../../../images/landing/linkedinCompany.svg";
import google from "../../../images/landing/googleCompany.svg";
import youtube from "../../../images/landing/youtubeCompany.svg";
import github from "../../../images/landing/githubCompany.svg";
import boostSpeed from "../../../images/landing/boostSpeed.png";
import evolvingCredibility from "../../../images/landing/evolvingCredibility.svg";
import learningIllustration from "../../../images/landing/learningIllustration.png";
import buildSmarter from "../../../images/landing/buildSmarter.png";
import skillUp from "../../../images/landing/skillUp.png";
import projectIllustration from "../../../images/landing/projectIllustration.png";
import earnMore from "../../../images/landing/earnMoreBoxImg.png";

import { Link } from "react-router-dom";
import { smallArrowRightSvg } from "../../UserHome/Base/SVG";

export const LearningPathSubheader = () => (
  <>
    <Typography>
      Focus on the skills that clients value most. Our AI tool 8020 creates your personalized learning path and suggests projects that showcase your expertise to potential clients.
    </Typography>
    <LearnMoreLink style={{ marginTop: 20 }}>
      <Link to="/8020">
        Learn more
        <span>{smallArrowRightSvg}</span>
      </Link>
    </LearnMoreLink>
  </>
);

export const ProjectSubHeader = () => (
  <>
    <Typography>
      Boost your credibility through strategic open-source contributions. Our AI copilot matches you with projects where your code can make a real impact and get noticed.
    </Typography>
    <LearnMoreLink style={{ marginTop: 20 }}>
      <Link to="/projects">
        Learn more
        <span>{smallArrowRightSvg}</span>
      </Link>
    </LearnMoreLink>
  </>
);

export const CopilotSubheader = () => (
  <>
    <Typography>
      Let AI match you with high-value opportunities that fit your expertise.
      Get insights on positioning your skills, craft winning proposals/resumes,
      and get hired.
    </Typography>

    <LearnMoreLink style={{ marginTop: 20 }}>
      <Link to="/playground">
        Learn more
        <span>{smallArrowRightSvg}</span>
      </Link>
    </LearnMoreLink>
  </>
);

export const companiesData = [
  {
    image: github,
    alt: "github",
  },
  {
    image: youtube,
    alt: "youtube",
  },
  {
    image: amazon,
    alt: "amazon",
  },
  {
    image: linkedin,
    alt: "linkedin",
  },
  {
    image: discord,
    alt: "discord",
  },

  {
    image: google,
    alt: "google",
  },
];

export const techJourneyEmpowermentData = [
  {
    img: boostSpeed,
    header: "Speed Matters",
    subheader: "To remain in demand, we must rapidly learn, create, and adapt",
    alt: "Boost Speed & Relevance",
    id: 1,
  },
  {
    img: evolvingCredibility,
    header: "Certifications Fall Short",
    subheader:
      "While AI dominates coding tests and certifications, developers must carve out a fresh path to credibility.",
    alt: "Evolving Credibility",
    id: 2,
  },
];

export const featuresData = [
  {
    img: learningIllustration,
    header: "Master High-Impact Skills",
    subheader: <LearningPathSubheader />,
    alt: "8020 Learning Path",
    id: 1,
  },

  {
    img: projectIllustration,
    header: "Build With Open Source",
    subheader: <ProjectSubHeader />,
    alt: "OpenSource Project Finder",
    id: 2,
  },
];

export const ourPlanOne = [
  {
    img: skillUp,
    header: "Build Your Professional Brand",
    subheader:
      "Transform your skills, expertise into an impressive portfolio (Streetcred). Our AI helps showcase your best work, learn, and build credibility that attracts better-paying opportunities.",
    alt: "Learn",
    id: "1",
  },
  {
    img: earnMore,
    header: "Win More Projects",
    subheader:
      "Let our AI BizDev Agent find high-value opportunities that match your expertise. Set optimal rates and win projects that pay what you're worth.",
    alt: "Discover Opportunities",
    id: "2",
  },
  {
    img: buildSmarter,
    header: "Handle Multiple Projects",
    subheader:
      "Take on more projects without dropping the ball. Our AI agents help you manage multiple clients and deadlines efficiently, turning side gigs into reliable income.",
    alt: "Build at Scale",
    id: "3",
  },
];

const LearnMoreLink = styled(Box)(({ theme }) => ({
  "& a": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    gap: 8,
    lineHeight: "20px",
    textDecoration: "none",
    fontWeight: 700,
    fontSize: 16,
    color: "#fefefe",

    "& span": {
      display: "flex",
    },

    "&:hover": {
      color: "#fefefe",
      textDecoration: "underline",
    },
  },
}));
