import { Box, Typography, styled } from "@mui/material";

import { MessageQuestion } from "iconsax-react";
// import earnMore from "../../../images/landing/OsPage/os-earn-more.png";
import buildMore from "../../../images/landing/OsPage/os-build-more.png";
import osFocus from "../../../images/landing/OsPage/os-focus.png";

export const data = [
  // {
  //   image: earnMore,
  //   title: "Scale Your Business Solo",
  //   subTitle:
  //     `Tublian OS gives you the power of a full development team. With AI agents handling everything from bidding to deployment, take on larger projects and build your software business—all on your own.`,
  //   id: 1,
  // },
  {
    image: buildMore,
    title: "Code & Build at Scale",
    subTitle:
      "Speed up development with AI agents that help you code, debug, and ship faster. Our Developer Agents assist with complex problems while maintaining quality across multiple projects.",
    id: 1,
  },
  {
    image: osFocus,
    title: "Manage & Grow With Ease",
    subTitle:
      "Handle multiple projects effortlessly with AI agents that manage deadlines, coordinate tasks, and keep clients updated. Scale your business without scaling your stress.",
    id: 2,
  },
];

const HowItWorksSection = () => {
  return (
    <>
      <HowTag>
        <MessageQuestion /> How
      </HowTag>
      <SectionHeader>Your Complete AI Team</SectionHeader>
      <Subtext>From coding to client management, Tublian's AI agents help you build faster and handle more projects. Focus on the big picture while your AI team takes care of execution.</Subtext>

      <SWDevFutureSection>
        {data?.map(({ image, title, subTitle, id }) => (
          <TubliOsAdvs odd={(id % 2 !== 0).toString()} key={id}>
            <Box className="textContent">
              <Typography component="h4">{title}</Typography>
              <Typography>{subTitle}</Typography>
            </Box>
            <Box className="illustrationWrap">
              <img src={image} alt={title} />
            </Box>
          </TubliOsAdvs>
        ))}
      </SWDevFutureSection>
    </>
  );
};

export default HowItWorksSection;

const HowTag = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  gap: "8px",
  borderRadius: "20px",
  background: "linear-gradient(180deg, #222325 0%, #383A3D 100%)",
  border: "1px solid #222325",
  width: "105px",
  height: "32px",
  padding: "4px 20px",
  color: "#FEFEFE",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: 700,
});

const SectionHeader = styled("h1")(({ theme }) => ({
  fontSize: "3rem",
  lineHeight: "3.828125rem",
  fontWeight: 700,
  textAlign: "center",
  color: "#FEFEFE",
  margin: "28px 0 20px",
  [theme.breakpoints.down(1025)]: {
    fontSize: "2.5rem",
    lineHeight: "3.19rem",
  },
  [theme.breakpoints.down(768)]: {
    fontSize: "1.5rem",
    lineHeight: "1.91375rem",
  },
}));

const Subtext = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: "2rem",
  color: "#FEFEFE",
  maxWidth: "759px",
  textAlign: "center",
  zIndex: 3,
  [theme.breakpoints.down(900)]: {
    maxWidth: "100%",
  },
  [theme.breakpoints.down(768)]: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
}));

const SWDevFutureSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "100px",
  marginTop: "60px",
  "& .textContent": {
    width: "539px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    flex: 1,
    "& h4": {
      color: "#FEFEFE",
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: "3.828125rem",
      [theme.breakpoints.down(1024)]: {
        fontSize: "2.5rem",
        lineHeight: "3.9rem",
      },

      [theme.breakpoints.down(720)]: {
        fontSize: "1.5rem",
        lineHeight: "1.91375rem",
      },
    },
    "& p": {
      color: "#FEFEFE",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "2.125rem",
      [theme.breakpoints.down(1024)]: {
        fontSize: "1.25rem",
        lineHeight: "2rem",
      },
      [theme.breakpoints.down(720)]: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
    },
  },

  [theme.breakpoints.down(1025)]: {
    padding: "0 76px",
  },
  [theme.breakpoints.down(768)]: {
    padding: 0,
    gap: "28px",
    width: "100%",
  },
}));

const TubliOsAdvs = styled(Box)<{ odd: string }>(({ theme, odd }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: odd === "true" ? "row" : "row-reverse",
  gap: "40px",
  "& .illustrationWrap": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    "& img": {
      maxWidth: "calc(100% - 16px)",
      maxHeight: "calc(100% - 48px)",
    },
    [theme.breakpoints.down(1200)]: {
      width: "627px",
    },
    [theme.breakpoints.down(1025)]: {
      width: "100%",
    },
    [theme.breakpoints.down(768)]: {
      height: "432px",
    },
  },
  [theme.breakpoints.down(1025)]: {
    flexDirection: "column",
  },
}));
