import { FC, useEffect, useState } from "react";
import { styled, Box, Tab, Tabs } from "@mui/material";

function TabPanel({
  children,
  value,
  index,
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: value === index ? "block" : "none",
      }}
    >
      {children}
    </Box>
  );
}

const NewCustomTabs: FC<NewCustomTabsProps> = ({
  tabsData,
  onTabChange,
  defaultValue,
  tabSwitchStyles,
}) => {
  const [tabValue, setTabValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setTabValue(defaultValue);
    }
  }, [defaultValue]);

  const handleTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    onTabChange?.(newValue);
  };

  const renderTabs = () => {
    return tabsData
      .filter((tab) => tab.show)
      .map((tab) => (
        <StyledTabLabel
          key={tab.value}
          value={tab.value}
          sx={{
            minWidth: "unset",
          }}
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <StyledTabValue
                sx={{
                  color: tabValue === tab.value ? "" : "#888",
                  background:
                    tabValue === tab.value
                      ? "linear-gradient(to right, #FBDA61 0%, #FF5ACD 100%)"
                      : "",
                  WebkitBackgroundClip: tabValue === tab.value ? "text" : "",
                  backgroundClip: tabValue === tab.value ? "text" : "",
                  WebkitTextFillColor:
                    tabValue === tab.value ? "transparent" : "",
                  textFillColor: tabValue === tab.value ? "transparent" : "",
                }}
              >
                {tab.label}
              </StyledTabValue>
              {tab.children && (
                <span style={{ color: "red" }}>{tab.children}</span>
              )}
            </div>
          }
        />
      ));
  };

  const renderTabPanels = () => {
    return tabsData.map((tab) => (
      <TabPanel key={tab.value} value={tabValue as number} index={tab.value}>
        {tab.content}
      </TabPanel>
    ));
  };

  return (
    <Box
      sx={{
        "& button:hover": {
          color: "#1e1e1e",
        },
      }}
    >
      <Box
        sx={{
          marginBottom: "20px",
          borderBottom: "1px solid #1e1e1e",
          ...tabSwitchStyles,
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabs}
          sx={{ "& .MuiTab-root": { marginRight: "24px" } }}
          TabIndicatorProps={{
            style: {
              background: "linear-gradient(to right, #FBDA61 0%, #FF5ACD 100%)",
            },
          }}
        >
          {renderTabs()}
        </Tabs>
      </Box>

      {renderTabPanels()}
    </Box>
  );
};

export default NewCustomTabs;

interface NewCustomTabsProps {
  tabsData: ITabData[];
  onTabChange?: (value: number) => void;
  defaultValue?: number;
  tabSwitchStyles?: any;
}

interface ITabData {
  value: number;
  label: React.ReactNode;
  content: React.ReactNode;
  show: boolean;
  children?: React.ReactNode;
}

const StyledTabLabel = styled(Tab)(({ theme }) => ({
  width: "auto",
  padding: "12px 20px",
  [theme.breakpoints.down(700)]: {
    "& *": {
      fontSize: "0.875rem",
    },
  },
}));

const StyledTabValue = styled(Box)({
  textTransform: "capitalize",
});
