import openSource from "../../../images/landing/openSourcee.png";
import openSourceMobile from "../../../images/landing/openSourceMobile.png";
import { logEvent } from "../../../libs/amplitude";
import GithubClient from "../../../libs/GithubClient";
import { RefCode } from "../../../libs/refCode";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import animationConfig from "../Streetcred/AnimationConfig";
import { useEffect } from "react";
import { styled } from "@mui/material";

const OpenSourceJourney = () => {
  const { ref, inView } = useInView({
    threshold: 0.6,
  });
  const animation = useAnimation();
  const contentAnimation = useAnimation();
  const buttonAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        ...animationConfig.animate2,
        transition: { delay: 0.2, ease: "easeIn" },
      });
      contentAnimation.start({
        ...animationConfig.animate2,
        transition: { delay: 0.4, ease: "easeIn" },
      });
      buttonAnimation.start({
        ...animationConfig.animate,
        transition: {
          delay: 0.4,
          // ease: "easeIn",
          type: "spring",
          duration: 2,
          bounce: 0.6,
        },
      });
    }
    if (!inView) {
      animation.start(animationConfig.initial4);
      contentAnimation.start(animationConfig.initial4);
      buttonAnimation.start(animationConfig.initial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const signInCode = RefCode.code();

  const handleGithubSignIn = () => {
    logEvent("SignUp", "public-view");
    GithubClient.signIn(signInCode);
    return false;
  };

  return (
    <OpensourceContainer>
      <OpensourceWrapper ref={ref}>
        <motion.div>
          <OpensourceBox1>
            <h2>Start Your Journey Today!</h2>
            <p>
              AI is transforming software development, making it faster and more
              accessible. Maximize your value and income with Tublian.
              <br />
              <br />
              Join Tublian now and prepare for the future.
            </p>
            <motion.div onClick={handleGithubSignIn} animate={buttonAnimation}>
              <OpensourceLink>Join with GitHub</OpensourceLink>
            </motion.div>
          </OpensourceBox1>
        </motion.div>

        <OpensourceBox2>
          <img
            src={openSource}
            alt="start your open source journey"
            className="desktop"
          />
          <img
            src={openSourceMobile}
            alt="start your open source journey"
            className="mobile"
          />
        </OpensourceBox2>
      </OpensourceWrapper>
    </OpensourceContainer>
  );
};

export default OpenSourceJourney;

const OpensourceContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#FDCA3A",
  borderRadius: 20,
  margin: "0",
  marginTop: "160px",
  [theme.breakpoints.down(800)]: {
    borderRadius: 0,
  },
  [theme.breakpoints.down(720)]: {
    marginTop: "80px",
  },
}));

const OpensourceLink = styled("button")(({ theme }) => ({
  color: "#fefefe",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  backgroundColor: "#121212",
  padding: "8px 32px",
  borderRadius: 20,
  textDecoration: "none",
  cursor: "pointer",
}));

const OpensourceBox1 = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  paddingLeft: 80,

  [theme.breakpoints.down(800)]: {
    padding: "20px 20px 0 20px",
    width: "100%",
  },
  "&>h2": {
    color: "#121212",
    fontSize: 36,
    fontWeight: 700,
    lineHeight: "52px",
    [theme.breakpoints.down(900)]: {
      fontSize: 30,
      lineHeight: "45px",
    },
    [theme.breakpoints.down(720)]: {
      width: "90%",
    },
    [theme.breakpoints.down(500)]: {
      width: "100%",
    },
  },
  "&>p": {
    color: "#121212",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "24px",
    [theme.breakpoints.down(720)]: {
      width: "90%",
    },
    [theme.breakpoints.down(500)]: {
      width: "100%",
    },
  },
}));

const OpensourceBox2 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down(1093)]: {
    width: "50%",
  },
  [theme.breakpoints.down(720)]: {
    width: "100%",
    height: "100%",
  },
  "&>img": {
    [theme.breakpoints.down(1093)]: {
      // width: "100%",
      // height: "100%",
      overflow: "hidden",
    },
  },
  "& .desktop": {
    [theme.breakpoints.down(720)]: {
      display: "none",
    },
  },
  "& .mobile": {
    [theme.breakpoints.up(720)]: {
      display: "none",
    },
  },
}));

const OpensourceWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  width: "100%",

  margin: "auto",
  maxWidth: 1200,
  [theme.breakpoints.down(1050)]: {
    paddingTop: 20,
    paddingBottom: 40,
  },
  [theme.breakpoints.down(900)]: {
    flexDirection: "row",
    paddingTop: 40,
  },
  [theme.breakpoints.down(800)]: {
    flexDirection: "column",
    gap: 20,
  },
}));
