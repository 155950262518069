import { Box, Button, styled, Tab, Tabs } from "@mui/material";
import scaleFasterBtn from "../../../images/landing/scaleFasterBtn.png";
import scaleFaster from "../../../images/landing/scaleFaster.png";
import handlePullRequests from "../../../images/landing/handlePullRequests.png";
import manageCodebase from "../../../images/landing/manageCodebase.png";
import { Link } from "react-router-dom";
import { smallArrowRightSvg } from "../../UserHome/Base/SVG";
import { useEffect, useState } from "react";
import { useTublianOsContext } from "../TublianOs/TublianOsContext";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            height: "fit-content",
            width: "fit-content",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const TosAgent = () => {
  const [value, setValue] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const { handleOpenWaitlistForm } = useTublianOsContext();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handlePause = () => {
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsPaused(false);
  };

  useEffect(() => {
    if (isPaused) return;

    const tabCount = 3;
    const interval = setInterval(() => {
      setValue((prevValue) => (prevValue + 1) % tabCount);
    }, 3000);

    return () => clearInterval(interval);
  }, [isPaused]);
  return (
    <Wrapper>
      <Image src={scaleFasterBtn} alt="scale faster" />
      <TosAgentContainer>
        <div className="flex1">
          <h1>Manage Multiple Projects Effortlessly</h1>
          <p>
            Let our AI agents help you code, debug, and maintain multiple projects efficiently. Take on more work without working more hours.
          </p>
          <ButtonWrapper>
            <Button
              style={{
                color: "#fefefe",
                padding: "8px 16px",
                borderRadius: "20px",
                // border: "1px solid #1AFF8C",
                background: `linear-gradient(#CCCB5D, #737234) padding-box,
               linear-gradient(109.15deg, #F9F871 0%, #F9F871 86.08%) border-box`,
                fontSize: "1rem",
                lineHeight: "1.5rem",
                fontWeight: 500,
              }}
              onClick={handleOpenWaitlistForm}
            >
              Join waitlist
            </Button>
            <LearnMoreLink>
              <Link to="/tublian-os">
                Learn more
                <span>{smallArrowRightSvg}</span>
              </Link>
            </LearnMoreLink>
          </ButtonWrapper>
          <div
            className="tabs"
            onMouseEnter={handlePause}
            onMouseLeave={handleResume}
          >
            <StyledTabs
              value={value}
              onChange={(event, newValue) => {
                handlePause();
                handleChange(event, newValue);
              }}
              aria-label=""
              orientation="vertical"
            >
              <StyledTab label="Build at Scale" />
              <StyledTab label="Manage Multiple Projects Effortlessly" />
              <StyledTab label="Support More Clients" />
            </StyledTabs>
          </div>
        </div>
        <div className="flex2">
          <CustomTabPanel value={value} index={0}>
            <div className="illustration">
              <TosImage src={scaleFaster} alt="Find freelance projects" />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="illustration">
              <TosImage src={manageCodebase} alt="identify niches" />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className="illustration">
              <TosImage
                src={handlePullRequests}
                alt="craft winning proposals"
              />
            </div>
          </CustomTabPanel>
        </div>
      </TosAgentContainer>
    </Wrapper>
  );
};

export default TosAgent;

const Wrapper = styled(Box)(({ theme }) => ({
  width: "calc(100% - 128px)",
  margin: "auto",
  maxWidth: 1312,
  // paddingBottom: "160px",

  [theme.breakpoints.down(1025)]: {
    width: "calc(100% - 81px)",
  },

  [theme.breakpoints.down(900)]: {
    margin: "auto",
  },
  [theme.breakpoints.down(720)]: {
    width: "100%",
    // paddingBottom: "80px",
  },
}));

const TosAgentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  color: "#fefefe",
  borderRadius: 20,
  gap: 40,
  overflow: "hidden",
  flexDirection: "row",
  height: "fit-content",

  [theme.breakpoints.down(1025)]: {
    gap: 20,
  },

  [theme.breakpoints.down(900)]: {
    flexDirection: "column",
    gap: 28,
    height: "fit-content",
  },

  [theme.breakpoints.down(800)]: {
    height: "fit-content",
    width: "100%",
  },
  "& .flex1": {
    width: "576px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    [theme.breakpoints.down(900)]: {
      width: "100%",
    },

    "&>h1": {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: "3.828125rem",

      [theme.breakpoints.down(720)]: {
        fontSize: "1.5rem",
        lineHeight: "2.125rem",
      },
    },

    "&>p": {
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "2rem",
      width: "512px",
      [theme.breakpoints.down(1025)]: {
        width: "450px",
      },
      [theme.breakpoints.down(800)]: {
        width: "100%",
      },
    },
  },

  "& .flex2": {
    // width: "55%",
    [theme.breakpoints.down(900)]: {
      width: "100%",

      justifyContent: "flex-end",
    },

    "&> .illustration": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      zIndex: 1,
      [theme.breakpoints.down(390)]: {
        height: "100%",
      },
    },
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  marginBottom: "20px",
  [theme.breakpoints.down(500)]: {
    flexDirection: "column",
    width: "fit-content",
  },
}));

const LearnMoreLink = styled(Box)(({ theme }) => ({
  "& a": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: 8,
    textDecoration: "none",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    color: "#F9F871",
    padding: "8px 16px",
    backgroundColor: "#3D3D1C",
    borderRadius: "20px",

    "& span": {
      display: "flex",
    },

    "&:hover": {
      color: "#fefefe",
      textDecoration: "none",
    },
  },
}));

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  orientation: "horizontal" | "vertical" | undefined;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  maxWidth: "fit-content",

  "& .MuiTabs-indicator": {
    display: "flex",
    // justifyContent: "left",
    backgroundColor: "transparent",
    left: 0,
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "4px",
    borderRadius: "14px",
    backgroundColor: "#FEFEFE",
    height: 24,
    top: "8px",
    position: "absolute",
  },
});

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  color: "#b7b7b7",
  paddingTop: "8px",
  paddingLeft: "8px",
  paddingBottom: "8px",
  paddingRight: "16px",
  minHeight: "fit-content",
  maxWidth: "fit-content",
  textAlign: "left",

  "&.Mui-selected": {
    color: "#fefefe",
    backgroundColor: "#19191B",
    border: "1px solid #222325",
    borderRadius: "10px",
    maxWidth: "fit-content",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const Image = styled("img")(({ theme }) => ({
  marginLeft: "-25px",
  [theme.breakpoints.down(371)]: {
    width: "100%",
  },
}));

const TosImage = styled("img")(({ theme }) => ({
  height: "689px",
  // width: "100%",
  [theme.breakpoints.down(500)]: {
    height: "459.41px",
    minHeight: "200px",
  },
}));
